import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default function BehandlingarPage({ data }) {
  const { content, title } = data.wpPage
  return (
    <Layout>
      <div className="min-h-screen flex flex-col items-center justify-center bg-primary/30">
        <div className="max-w-4xl mx-auto px-4 md:px-8 py-8 md:py-16">
          <h1 className="page-header">{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="wp-content space-y-4"
          />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    wpPage(databaseId: { eq: 127 }) {
      content
      title
      id
    }
  }
`
